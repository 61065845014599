<template>

  <div>

    <div class="row mb-2">
      <div class="col-sm-4">
      </div>
      <div class="col-sm-8">
        <div class="float-sm-right">
          <router-link to="/rentals/add-controls" class="btn btn-danger mb-2"
          ><i class="mdi mdi-plus-circle mr-1"></i> Configure new control </router-link
          >
        </div>
      </div>
      <!-- end col-->
    </div>
    <div class="card">
      <div class="card-body">
        <h4 class="header-title mb-4">Rental Controls</h4>
        <b-tabs>
          <b-tab title="Arrival Controls" active>
            <div class="p-1"></div>
            <div class="row">

              <div class="col-sm-6" v-for="control in arrivalControls">

                <div class="form-group">
                  <div class="input-group input-group-merge mb-1 pt-1">
                    <input type="text" class="form-control" :value="control.label">
                    <div class="input-group-append">
                      <div class="input-group-text bg-danger" @click="onRemoveControlTapped(control.id)" style="cursor: pointer">
                        <span class="fa fa-trash text-white"></span>
                      </div>
                    </div>
                  </div>
                  <span v-if="control.required_for_arrival === 'yes'" class="text-danger "> This field is required</span>
                  <span v-else class="text-black-50"> This field is optional</span>

                </div>

              </div>
            </div>
          </b-tab>
          <b-tab title="Departure Controls" class="mt-1">
            <div class="p-1"></div>
            <div class="row">

              <div class="col-sm-6" v-for="control in departureControls">

                <div class="form-group">
                  <div class="input-group input-group-merge mb-1 pt-1">
                    <input type="text" class="form-control" :value="control.label">
                    <div class="input-group-append">
                      <div class="input-group-text bg-danger" @click="onRemoveControlTapped(control.id)" style="cursor: pointer">
                        <span class="fa fa-trash text-white"></span>
                      </div>
                    </div>
                  </div>
                  <span v-if="control.required_for_arrival === 'yes'" class="text-danger "> This field is required</span>
                  <span v-else class="text-black-50"> This field is optional</span>
                </div>

              </div>
            </div>

          </b-tab>
        </b-tabs>

      </div>
    </div>
  </div>






</template>

<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/Page-header";
import appConfig from "../../../../../app.config";
import {confirm} from "../../../../utils/functions";

/**
 * Tabs & Accordions component
 */
export default {
  page: {
    title: "Tabs & Accordions",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: {
    Layout,
    PageHeader,
  },
  computed:{
    arrivalControls() {
      return this.$store.getters.GET_ARRIVAL_RENTAL_CONTROLS;
    },
    departureControls() {
      return this.$store.getters.GET_DEPARTURE_RENTAL_CONTROLS;
    }
  },
  methods: {
    onRemoveControlTapped(id){
      confirm("Remove this control", () => {
        this.$store.dispatch('removeRentalControl', id)
      })
    },
  },
  created() {
    this.$store.dispatch('getRentalControls')
  }
};
</script>
